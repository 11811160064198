.back-to-top {
  height: 60px;
  width: 60px;
  padding: 11px 10px;
  position: fixed;
    left: 91%;
    top: 80%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
 
}

.back-to-top-button {
  background-image: url(/public/btt-button.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: opacity 0.3s ease;
  height: 60px;
  width: 60px;
  border: unset;
  top: -3px;
  right: 3px;
  position: relative;
  filter: drop-shadow(0 2px 2px rgb(10, 10, 10));
}

.back-to-top-button:hover {
  opacity: 1;
}


@media (max-width: 768px) {
  .back-to-top {
    height: 25px;
    width: 25px;
    padding: 11px 10px;
    position: fixed;
    left: 44%;
    top: 85%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.back-to-top-button {
  background-image: url(/public/back-to-top-responsive.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: opacity 0.3s ease;
  border: unset;
  top: -3px;
  right: 3px;
  position: relative;
}
}
