.project-container {
  display: grid;
  grid-template-columns: 0.1fr auto auto 0.1fr;
  grid-template-rows: 0.1fr auto auto 0.1fr;
  gap: 1rem;
  grid-auto-flow: row;
  margin-top: 30px;
  grid-template-areas:
    ". . . ."
    ". top-section-projects top-section-projects ."
    ". bottom-section-projects bottom-section-projects ."
    ". . . .";
}

.top-section-projects {
  grid-area: top-section-projects;
}

.bottom-section-projects {
  grid-area: bottom-section-projects;
}

.top-section-projects {
 max-height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bottom-section-projects {
  max-height:300px ;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.project-header {
  max-width: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  text-align: left;
  margin-top: auto;
}

.project-button {
  max-width: 300px;
  border: solid 3px black;
  border-radius: 25px;
  padding: 10px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 1.2rem;
}

.project-description {
  font-size: 20px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #fafaf5;
  border-radius: 24px;
  padding: 16px;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;
  position: relative;
}

.project-icons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.project-images {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  max-height:400px ;
 
 
}

.image-container img {
  max-width: 90%;
  height: auto;
  margin-bottom: 10px;
}


.project-icon {
  width: 100%;
  max-width: 200px;
  height: 150px;
  object-fit: contain;
} 

.see-more-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
  margin-top: 8px;
  font-style: italic;
  font-family: sans-serif;
   position: absolute;
   bottom: 10px;
   right: 20px;
}

.see-more-link:hover {
  text-decoration: underline;
}

.see-more-link img {
  margin-left: 4px;
  width: 21px;
  margin-bottom: 2px;
  margin-right: 2px;

}

@media (max-width: 760px) {
  .project-container {
    display: grid;
    grid-template-columns: 0fr 1fr 1fr 0fr;
    grid-template-rows: auto;
    gap: 1rem;
    grid-auto-flow: row;
    grid-template-areas:
      ". . . ."
      ". top-section-projects top-section-projects ."
      ". bottom-section-projects bottom-section-projects ."
      ". . . .";
  }
  .title-container-projects {
      width: 100%;
      top: 10px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
  
  }
  .project-button {
    max-width: 200px;
    border: solid 3px black;
    border-radius: 25px;
    padding: 10px;
    font-size: 20px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 1rem;
  }

  .top-section-projects {
    max-height: 290px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .project-description {
    font-size: 14px;
    border: 1px solid #ddd;
    text-align: left;
    background-color: #fafaf5;
    border-radius: 24px;
    padding: 10px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.5);
  }
  .project-icons > .image-container {
    border-radius: 10px;
    padding: 5px;
    height: 35px !important;
  }

  .image-container > .project-icon {
    height: 30px;
  }

  .project-images {
    display: none;
    flex-direction: row;
    align-items: baseline;
  }
  .bottom-section-projects {
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

@media (max-width: 400px) {
  .carousel-item {
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
}
}
