html {
  scroll-behavior: smooth;
}

.App {
  position: relative;
  min-height: fit-content;
  overflow: hidden;
  margin-bottom: 100px;
}

section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
#home {
  margin-top: 0px;
}

.sidebar-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

.nav-icon {
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
}

h1 {
  font-size: 48px;
  color: #ffff;
}

.doodle {
  position: absolute;
}

.doodle1 {
  display: flex;
  left: -5%;
  top: 3%;
  height: 210px;
}
.doodle2 {
  height: 200px;
  display: flex;
  right: -37px;
}

.doodle3 {
  display: flex;
  top: 13%;
  right: -1%;
  height: 120px;
}
.doodle4 {
  display: flex;
  height: 400px;
  top: 30%;
  left: -10%;
}
.doodle5 {
  display: flex;
  height: 400px;
  top: 37%;
  right: -15%;
}
.doodle6 {
  display: flex;
  height: 400px;
  top: 50%;
  left: -15%;
  transform: rotate(20deg);
}
.doodle7 {
  display: flex;
  top: 96%;
  height: 200px;
}

.doodle8 {
  top: 53%;
  height: 400px;
  right: -15%;
}
.doodle9 {
  top: 75%;
  height: 400px;
  left: -15%;
}
.doodle10 {
  top: 75%;
  height: 400px;
  right: -15%;
}

@media (max-width: 768px) {
  section {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  
  }

  .doodle1 {
    display: flex;
    height: 80px;
    left: -10px;
    top: 7%;
  }

  .doodle2 {
    height: 80px;
    display: flex;
    right: -37px;
  }

  .doodle3 {
    position: absolute;
    display: none;
    top: 12%;
    right: -5%;
    height: 40px;
  }

  .doodle4 {
    display: flex;
    height: 150px;
    top: 38%;
    left: -28%;
    z-index: -1;
  }

  .doodle5 {
    display: none;
    top: 40%;
    height: 150px;
  }
  .doodle6 {
    display: flex;
    top: 56%;
    height: 150px;
    left: -38%;
  }
  .doodle8 {
    display: none;
  }
  .doodle7 {
    display: flex;
    top: 96%;
    height: 80px;
}
  .doodle9 {
    height: 150px;
  }
  .doodle10 {
    height: 150px;
    display: none;
  }
}
@media (max-width: 400px){
.doodle1{
  display: flex;
  height: 80px;
  left: -10px;
  top: 5%;
}

} 
/* @media (max-width: 600px) {
  .doodle1 {
    display: flex;
    height: 100px;
    left: -54px;
    top: 5.5%
  }

  .doodle3 {
    position: absolute;
    display: flex;
    top: 15%;
    right:-3%;
    height: 45px;
  }

  .doodle4 {
    display: flex;
    height: 150px;
    top: 38%;
    left: -28%;
    z-index: -1;
  }

  .doodle5 {
    display: flex;
    top: 55%;
    height: 150px;
  }

  .doodle6 {
    display: flex;
    top: 56%;
    height: 150px;
    left: -38%;
  }

  .doodle7 {
    display: flex;
    top: 96%;
    z-index: 3;
    left: -69%;
    height: 100px;
  } */

