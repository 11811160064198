@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  width: auto;
  height: 100vh;
  background-image: url("../public/background-body.svg");
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
  padding: 0;
  margin: 0;
  overflow-x: hidden;

}

p{
  font-family: "Inter", sans-serif;

}

code {
  font-family: "Inter", sans-serif;
}
