.carousel {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.title-container-projects{
  position: absolute;
  width: 600px;
  top: 9%;
  left: 16%;
}
.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.carousel-item {
  min-width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-control {
  position: absolute;
  top: 93%;
  transform: translateY(-50%);
  border: none;

  padding: 0.5rem;
  cursor: pointer;
}

.prev {
  margin-top: 1.5rem;
  background-image: url(/public/carousel-button-left.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
  left: 45%;
  background-color: transparent;
  filter: drop-shadow(3px 3px 5px #000000);
}

.next {
  margin-top: 1.5rem;
  background-image: url(/public/carousel-button-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
  right: 45%;
  background-color: transparent;
  filter: drop-shadow(3px 3px 5px #000000);
}
@media (min-width: 1900px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 22%;
}

}
@media (min-width: 1800px) and (max-width: 1900px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 18%;
}
}
@media (min-width: 1700px) and (max-width: 1800px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 13%;
}
}
@media (min-width: 1600px) and (max-width: 1700px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 13%;
}
}
@media (min-width: 1500px) and (max-width: 1600px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 8%;
}
}
@media (min-width: 1200px) and (max-width: 1500px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 7%;
}
}
@media (min-width: 1000px) and (max-width: 1200px){
  .title-container-projects {
    position: absolute;
    width: 600px;
    top: 4%;
    left: 1%;
}
}



@media (max-width: 768px) {
  .carousel-item {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

  .carousel-control {
    position: absolute;
    top: 59%;
    transform: translateY(-50%);
    border: none;
  
    padding: 0.5rem;
    cursor: pointer;
  }
  .prev {
    background-image: url(/public/responsive-carousel-control-left.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
    width: 50px;
    left: -1%;
    background-color: transparent;
    filter: unset;

  }
  
  .next {
    background-image: url(/public/responsive-carousel-control-right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
    width: 50px;
    right: -1%;
    background-color: transparent;
    filter: unset;

  }
}
@media (max-width: 440px){
  .carousel-control {
    position: absolute;
    top: 61%;
    transform: translateY(-50%);
    border: none;
    padding: 0.5rem;
    cursor: pointer;}
}

@media (max-width: 414px){
  .carousel-control {
    position: absolute;
    top: 62%;
    transform: translateY(-50%);
    border: none;
    padding: 0.5rem;
    cursor: pointer;}
}

@media (max-width: 390px){
  .carousel-control {
    position: absolute;
    top: 63%;
    transform: translateY(-50%);
    border: none;
    padding: 0.5rem;
    cursor: pointer;
}
  
}

@media (max-width: 360px){
  .carousel-control {
    position: absolute;
    top: 64%;
    transform: translateY(-50%);
    border: none;
    padding: 0.5rem;
    cursor: pointer;
}
  
}
@media (max-width: 375px){
  .carousel-control {
    position: absolute;
    top: 63%;
    transform: translateY(-50%);
    border: none;
    padding: 0.5rem;
    cursor: pointer;
}
  
}
