.mobile-phone {
  position: relative;
  width: 350px;
  max-height: 702px;
  height: 100%;
  background-color: #2c3e50; /* Color of the phone body */
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.screen {
  width: 90%;
  max-height: 600px;
  height: 100%;
  background-color: #f4f4f4;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  padding-top: 20px;
}
.phone-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: -webkit-fill-available;
  margin-top: 3em;
  margin-bottom: auto;
  margin-left: 2em;
  margin-right: 2em;
}
.responsive-image.statusbar{
  margin: 0px;
  margin: auto;
    position: absolute;
    top: 20px;
}

@media (max-width: 700px) {
  .phone-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: -webkit-fill-available;
    margin-top: 1em;
    margin-bottom: 1rem;
    margin-left: 2em;
    margin-right: 2em;
  }
}
