.textbox-container {
    background-color: #FAFAF5;
    border-radius: 24px;
    padding: 16px; /* Ajusta el relleno según sea necesario */
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, .5); /* Sombra ligera */
    margin-bottom: 16px; /* Espacio inferior entre cajas */
  }
  
  .textbox-content {
    font-size: 20px;
    width: 100%; /* Ajusta el ancho al 100% para que sea responsive */
  }
  @media (max-width: 768px)  {
    .textbox-content {
      text-align: center;
      font-size: 14px;
      width: 100%; /* Ajusta el ancho al 100% para que sea responsive */
    }
    .text-box-second-paragraph {
      display: none;
    }
  }
