a{
  text-decoration: none;
}
.icon-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34C759;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.icon-button.first-button {
  background-color: #5C86F4 !important;
}

.icon {
  width: 40px;
  height: 30px;
  margin-right: 0px;
}

.button-text {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  display: none;
}
@media (max-width: 700px){
  .icon-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #34C759;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 0px;
  }
  
}

