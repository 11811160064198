@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400..700;1,400..700&display=swap");

h1 {
  font-family: "Familjen Grotesk", sans-serif;
}

.about-me-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 0.2fr;
  grid-template-rows: 0.5fr 0.7fr 1.3fr 0.5fr;
  gap: 1rem 1rem;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . ."
    ". title-section-container about-personal-container ."
    ". about-photo-container text-box-container ."
    ". about-personal-mobile-container . ."
    ". . . .";
  padding: 5% 5%;
  width: 100%;
}

.title-section-container {
  grid-area: title-section-container;
}

.about-personal-container {
  grid-area: about-personal-container;
}

.about-photo-container {
  grid-area: about-photo-container;
}

.text-box-container {
  grid-area: text-box-container;
}

.about-personal-mobile-container {
  grid-area: about-personal-mobile-container;
}

/* Estilos adicionales específicos */
.title-section-container {
  width: 100%; /* Ensure full width */
  position: relative;
}

.about-photo-container {
  max-width: 530px;
  height: auto;
  position: relative;
  height: fit-content;
  top: -10rem;
}

.about-personal-container {
  position: relative;
  left: 1rem;
}

.about-personal-mobile-container {
  display: none;
  max-width: 530px;
  max-height: 280px;
}

.text-box-container {
  left: -5rem;
  position: relative;
  max-width: 720px;
  max-height: 430px;
}

/* Media Queries para Responsiveness */

/* Tablets y dispositivos más pequeños */
@media (max-width: 1024px) {
  .about-me-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "title-section-container"
      "about-personal-container"
      "about-photo-container"
      "text-box-container"
      "about-personal-mobile-container";
  }
  p {
    font-size: 16px;
  }
  .about-photo-container {
    top: 0;
    max-width: 100%;

  }

  

  .about-personal-container {
    left: 0;
  }

  .text-box-container {
    left: 0;
    max-width: 100%;
  }

  .about-personal-mobile-container {
    display: none;
  }
}

/* Teléfonos móviles */
@media (max-width: 768px) {
  .about-me-container {
    height: auto;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto;
    gap: 0rem;
    grid-template-areas:
      "title-section-container"
      "about-photo-container"
      "text-box-container"
      "about-personal-container"
      "about-personal-mobile-container";
  }

  p {
    font-size: 14px;
  }

  .about-photo-container {
    top: 0;
    max-width: 100%;

    .about-photo {
      display: none;
    }

    .about-photo-mobile{
      margin-top: 1rem;
      display: block;
    }
  }

  .about-personal-container {
    left: 0;
    display: none;
  }

  .text-box-container {
    margin-top: 1rem;
    left: 0;
    max-width: 100%;
  }

  .about-personal-mobile-container {
    margin-top: 1rem;
    display: block;
  }
}
