.image-container {
  max-width: 100%;
  height: auto;
}
.responsive-image {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
}
