#projects-section {
    height: 100vh;
    padding: 0 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    
  }
  .about-me-title-projects{
    position: relative ;
    top: 50px;
  }

  @media (max-width: 768px){

  }