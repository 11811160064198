.contact-section-container {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 1.6fr 0.2fr;
  grid-template-rows: 0.2fr 0.8fr 1.5fr 3.3fr 0.2fr;
  gap: 3em 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . ."
    ". contact-title-section-container contact-phone-container ."
    ". contact-textbox-container contact-phone-container ."
    ". contact-sticky-container contact-phone-container ."
    ". . . .";
}

.contact-title-section-container {
  grid-area: contact-title-section-container;
}

.contact-phone-container {
  grid-area: contact-phone-container;
}

.contact-textbox-container {
  grid-area: contact-textbox-container;
}

.contact-sticky-container {
  grid-area: contact-sticky-container;
}

.contact-text-box-container,
.contact-phone-container,
.contact-sticker-container {
  display: flex;
  justify-content: center;
}

/* Media Queries para Responsiveness */

/* Tablets y dispositivos más pequeños */
@media (max-width: 1024px) {
  .contact-section-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "contact-title-section-container"
      "contact-phone-container"
      "contact-textbox-container"
      "contact-sticky-container";
    gap: 1em;
  }

  .contact-title-section-container,
  .contact-phone-container,
  .contact-textbox-container,
  .contact-sticky-container {
    justify-content: center;
    align-items: center;
  }
}

/* mobile */
@media (max-width: 768px) {

  #contact-section{
    padding-left: 5%;
    padding-right: 5%;
  }
  .contact-section-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "contact-title-section-container"
      "contact-textbox-container"
      "contact-phone-container"
      "contact-sticky-container";
    gap: 1em;
    margin-bottom: 150px;
  }

  .contact-title-section-container,
  .contact-textbox-container,
  .contact-sticky-container {
    justify-content: center;
    align-items: center;
  }
  .contact-phone-container {
    padding: 2%;
    margin-bottom: 0rem;
  }

  .contact-textbox-container {
    display: none;
  }
}
