#home-section {
  width: auto;
  height:100vh;
  padding: 0;
  margin: 0;

  .home-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: -webkit-fill-available;
  }
  .image-container {
    .logo-pao-design {
      max-width: 300px;
      margin-bottom: 80px;
    }

    .logo-pao-design-mobile {
      display: none;
    }
    .welcome-title {
      width: 700px;

      margin-bottom: 100px;
    }
    .welcome-tag {
      max-width: 300px;
      filter: drop-shadow(0 2px 2px rgb(10, 10, 10));

    }
  }

  @media (max-width: 600px) {
    .home-section-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }

    .image-container {
      margin-top: -10px;
      .logo-pao-design {
        display: none;
      }
      .logo-pao-design-mobile {
        display: block;
        height: auto;
        width: 160px;
        position: relative;
        bottom: 80%;
      }
      .welcome-title {
        width: 300px;
        padding: 10px;
        margin-bottom: 40px;
      }
      .welcome-tag {
        max-width: 180px;
        margin-bottom: 15%;
      }
    }

    /* .welcome-year-title {
      font-size: 30px;
      color: black;
      padding-left: 19px;
      position: absolute;
      top: 10px;
    } */
  }

  @media (min-width: 600px) and (max-width: 768px) {
    .logo-pao-design-mobile .image-container {
      margin-top: -10px;
      .logo-pao-design {
        display: none;
      }

      .logo-pao-design-mobile {
        width: 250px;
        height: auto;
        display: block;
      }
      .welcome-title {
        width: 350px;
        padding: 10px;
        margin-bottom: 30px;
      }
      .welcome-tag {
        max-width: 300px;
      }
    }

    .welcome-year-title {
      font-size: 30px;
      color: black;
      padding-left: 19px;
      position: absolute;
      top: 10px;
    }
    .image-container {
      .logo-pao-design {
        max-width: 300px;
        margin-bottom: 70px;
      }
      .welcome-title {
        width: 350px;
        padding: 10px;
        margin-bottom: 30px;
      }
      .welcome-tag {
        max-width: 300px;
      }
    }
  }
}
@media (min-width: 380px) {


}