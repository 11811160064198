.phone-container-component {
  padding: 5% 10%;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: 600px;
  background-color: #7795ff;
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 20px 20px 0px 10px #42445a;

  .corner {
    width: 15px;
    height: 15px;
    background-color: white;
    border: 3px solid black;
    position: absolute;
  }

  .top-left {
    top: -9px;
    left: -9px;
  }

  .top-right {
    top: -9px;
    right: -9px;
  }

  .bottom-left {
    bottom: -9px;
    left: -9px;
  }

  .bottom-right {
    bottom: -9px;
    right: -9px;
  }
}

@media (max-width: 700px) {
  .phone-container-component {
    /* margin-top: 1rem; */
    padding: 5% 5%;
    position: relative;
    width: 100%;
    height: 90%;
    max-width: 276px;
    max-height: 600px;
    box-shadow: 10px 10px 0px 0px #42445a;

    .corner {
      width: 6px;
      height: 6px;
      background-color: white;
      border: 3px solid black;
      position: absolute;
  }
  
  }


}
