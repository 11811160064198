.welcome-year {
  width: 200px;
  height: 120px;
  background-image: url("../../../public/welcome-date.svg");
  background-size: cover; /* Hace que la imagen cubra todo el área */
  background-position: center;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  bottom: 20%;
  left: -45%;
}

.welcome-year-title {
  font-size: 48px;
  color: black;
  padding-left: 19px;
  position: absolute;
  top: 16px;
}

@media (max-width: 600px) {
  .welcome-year {
    width: 82px;
    height: 50px;
    position: relative;
    left: 8%;
    bottom: 25%;
 
  .welcome-year-title {
    font-size: 15px ;
    color: black;
    padding-left: 19px;
    position: absolute;
    top: 13px;
  }
}
}

/* @media (min-width: 600px) and (max-width: 768px) {
  .welcome-year {
    width: 82px;
    height: 50px;
    position: relative;
    left: 0;
  }
   .welcome-year-title {
    font-size: 15px;
    color: black;
    padding-left: 19px;
    position: absolute;
    top: 0px;
  }
} */


