.sidebar-navigation {
  position: fixed;
  left: 90%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 1000;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 15px 0;
  transition: opacity 0.3s;
}

.nav-icon {
  width: 70px; /* Adjust as needed */
  height: 70px; /* Adjust as needed */
}

.nav-button.active {
  transform: scale(1.3); /* Increase size by 5% */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .sidebar-navigation {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-radius: 0 0 10px 10px;
    background-color: #e7e7e7b8;
}

  .nav-button {
    margin: 0 10px;
  }

  .nav-icon {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
  }
}
