@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400..700;1,400..700&display=swap');

#cv-section {
  height: 100vh;
    padding: 0 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-content: center;
    justify-content: center;

}

.cv-section-container {
 
  display: grid;
  grid-template-columns: 0fr 1.8fr 1.9fr 0fr;
  grid-template-rows: auto auto auto auto;
  gap: 1em 1em;
  grid-auto-flow: row dense;
  grid-template-areas:
      "cv-title-section-container cv-title-section-container . ."
      ". cv-text-box-container cv-text-box-container ."
      ". cv-image-container cv-skills-container ."
      ". . . .";
  width: 100%;
  align-items: center;
  align-content: center;
  justify-items: center;
  padding:10%;
}

.cv-title-section-container {
  grid-area: cv-title-section-container;
  max-width: 600px;
  width: 100%; /* Ensure full width */
 margin: 1rem;
 position: relative;
 left: 1rem;
}

.cv-image-container {
  grid-area: cv-image-container;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center;
  position: relative;
}
.download-qr{
  max-height: 250px;
}
.cv-envelope-container{
  position: relative;

}
.cv-balloon-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
}

.cv-ballon-1, .cv-balloon-2, .cv-envelope-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.cv-text-box-container {
  grid-area: cv-text-box-container;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 1rem;
}

.cv-skills-container {
  grid-area: cv-skills-container;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 

}

.cv-skills {
  display: block; /* Default display for larger screens */
}

.cv-skills-mobile {
  display: none; /* Hide by default */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .cv-section-container {
    grid-template-columns: 1fr;
    gap: 0em;
    grid-template-areas:
        "cv-title-section-container"
        "cv-image-container"
        "cv-text-box-container"
        "cv-skills-container";
        padding: 3% 3%;
  }
  .cv-title-section-container{
    left: 0;
    padding: 0rem;
  }

  .cv-envelope-container{
    position: relative;
    top: 0rem;
  }

  .cv-image-container{
    grid-area: cv-image-container;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    margin: .5rem;
  }

  .cv-balloon-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .cv-envelope-container{
    height: auto;
    width: auto;
    margin: 1rem;
  }
  .cv-ballon-1, .cv-balloon-2, .cv-envelope-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0rem;
  }
  .cv-text-box-container{
    margin-top: 0rem;
    top:0rem ;
  }
  .stext-box-container{
    margin-top: 0rem;
  }

  .cv-skills {
    display: none; /* Hide the desktop version on small screens */
  }

  .cv-skills-mobile {
    display: block; /* Show the mobile version on small screens */
  }
}
