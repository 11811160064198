.comic-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fffcfc;
  border: 2px solid black;
  border-radius: 20px 20px 0 20px;
  max-width: 260px;
  height: 60px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
  }

  .button-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .button-text {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fffcfc;
    border-bottom: 0;
    border-left: 0;
    margin-left: -5px;
    box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 768px) {
  .comic-button {
    height: 40px;
    padding: 10px;
    margin: 20px;
  }

  .comic-button__icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .comic-button__text{
    font-size: 10px;
  }
}
